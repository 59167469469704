import * as React from 'react';
import {
  NavLink as ReactLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import {
  Collapse,
  Button,
  Modal,
  ModalBody,
  Form,
  FormFeedback,
  Input,
  Label,
  CustomInput,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineFileAdd } from 'react-icons/ai';
import {
  Alerts,
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from '../utils/alerts';
import { AuthContext } from '../../providers/AuthProvider';
import { modalToggle } from 'actions/pages/privacyPolicy';
import { connect } from 'react-redux';
import { validateEmail } from '../utils/inputs/email';
import { toggleOverlay } from '../../actions/loadingOverlay';
import { liveStreamDispatch } from 'actions/home';
import { has, rest } from 'lodash';
import jsonp from 'jsonp';
import config from '../../config.js';
import { AuthConsumer } from '../../providers/AuthProvider';
import 'styles/layout/_navigation.scss';
import {
  fetchCategories,
  fetchTags,
} from '../../actions/story/storyList';

import { getCurrentUserProfileById } from '../../actions/user/profile';

import { TiStarOutline } from 'react-icons/ti';
import NewsflashBanner from './NewsflashBanner';
import { API } from 'aws-amplify';
import { NewsflashSettingsData } from 'components/admin/settings/NewsflashSettings';

interface State extends Alerts {
  isOpen: boolean;
  mailChimpModal: boolean;
  hideMailChimp: boolean;
  email: string;
  emailInvalid?: boolean;
  isOnboardingShown: boolean;
  newsflash: NewsflashSettingsData | null
}

interface Props extends RouteComponentProps<{}> {
  modalToggle: Function;
  toggleOverlay: Function;
  fixed: boolean;
  fetchCategories: Function;
  fetchTags: Function;
  liveStreamDispatch: Function;
  getCurrentUserProfile: Function;
  profileId: number;
}

class HeaderClass extends React.Component<Props, State> {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  _isMounted: boolean = false;

  constructor(props: Props) {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    super(props);

    this.state = {
      isOpen: false,
      mailChimpModal: false,
      hideMailChimp: false,
      isOnboardingShown: true,
      email: '',
      newsflash: null
    };

    this.toggle = this.toggle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.closeMenuExpand = this.closeMenuExpand.bind(this);
  }

  componentDidMount(): void {
    this._isMounted = true;
    document.addEventListener('mousedown', this.handleClickOutside);

    window.addEventListener('hashchange', () => {
      const { location: { hash } } = window;
      if (hash && hash.endsWith('helpStoriesModal')) {
        this.props.modalToggle('HELP_STORIES_MODAL', true);
      }
      /* ... */
    });

    if (window.location.pathname === '/') {
setTimeout(() => this.props.liveStreamDispatch(true), 3000); 
}
    if (window.location.pathname === '/live') {
setTimeout(() => this.props.liveStreamDispatch(true, true), 3000); 
}
    // setInterval(async () => {
    //   try {
    //     const response = await fetch(`https://api.twitch.tv/helix/streams?user_login=${config.auth.TWITCH_USER}`, {
    //       mode: 'cors',
    //       method: 'GET',
    //       headers: {
    //         'Accept': 'application/vnd.twitchtv.v5+json',
    //         'Client-ID': config.auth.TWITCH_CLIENT_ID,
    //         'Authorization': 'Bearer ' + config.auth.TWITCH_BEARER_TOKEN
    //       }
    //     });
    //     const responseJSON = await response.json();
    //     if (responseJSON.data.length > 0) {
    //       document.getElementById('liveButton')!.classList.add('pulsing');
    //     } else {
    //       document.getElementById('liveButton')!.classList.remove('pulsing');
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   };
    //   }, 5000);

    this.props.fetchCategories();
    this.props.fetchTags();
    this.props.getCurrentUserProfile(0);
    this.fetchNewsflash()
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async fetchNewsflash() {
    const result = await API.get('tba21', 'admin/settings', { queryStringParameters: { name: 'newsflash' } })
    this.setState({ newsflash: result.newsflash })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeMenuExpand() {
    this.setState({
      isOpen: false,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClickOutside(event: any) {
    const t = event.target;
    if (this.state.isOpen && !t.classList.contains('dropdown-toggle')) {
      // timeout is needed so navlink could navigate first when clicked
      setTimeout(() => this.closeMenuExpand(), 100);
    }
  }

  AdminRoutes(): JSX.Element {
    return (
      <UncontrolledDropdown inNavbar nav>
        <DropdownToggle nav caret>
          Admin
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <NavItem>
              <NavLink
                exact
                tag={ReactLink}
                className="nav-link"
                activeClassName="active"
                to="/admin/Collections/"
              >
                Collections
              </NavLink>
            </NavItem>
          </DropdownItem>
          <DropdownItem>
            <NavItem>
              <NavLink
                exact
                tag={ReactLink}
                className="nav-link"
                activeClassName="active"
                to="/admin/Items"
              >
                Items
              </NavLink>
            </NavItem>
          </DropdownItem>
          {/* <DropdownItem>
            <NavItem>
              <NavLink
                exact
                tag={ReactLink}
                className="nav-link"
                activeClassName="active"
                to="/admin/announcements"
              >
                Announcements
              </NavLink>
            </NavItem>
          </DropdownItem> */}
          <DropdownItem>
            <NavItem>
              <a
                className="nav-link"
                href={config.urls.WP_URL + 'wp-admin/'}
              >
                Journeys
              </a>
            </NavItem>
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem>
            <NavItem>
              <NavLink
                exact
                tag={ReactLink}
                className="nav-link"
                activeClassName="active"
                to="/admin/ManageUsers"
              >
                Manage Users
              </NavLink>
            </NavItem>
          </DropdownItem>
          <DropdownItem>
            <NavItem>
              <NavLink
                exact
                tag={ReactLink}
                className="nav-link"
                activeClassName="active"
                to="/admin/settings"
              >
                Settings
              </NavLink>
            </NavItem>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // eslint-disable-next-line @typescript-eslint/tslint/config
  AccountRoutes({profileId}): JSX.Element {
    return (
      <>
        <UncontrolledDropdown inNavbar nav>
          <DropdownToggle nav caret>
            Account
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link"
                  activeClassName="active"
                  to="/Profile"
                >
                  My Profile
                </NavLink>
              </NavItem>
            </DropdownItem>
            <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link"
                  activeClassName="active"
                  to="/contributor/items"
                >
                  My Items
                </NavLink>
              </NavItem>
            </DropdownItem>
            <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link"
                  activeClassName="active"
                  to="/contributor/collections"
                >
                  My Collections
                </NavLink>
              </NavItem>
            </DropdownItem>
            {/* <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link"
                  activeClassName="active"
                  to="/contributor/announcements"
                >
                  My Announcements
                </NavLink>
              </NavItem>
            </DropdownItem> */}
            <DropdownItem>
              <NavItem>
                <a
                  className="nav-link"
                  href={config.urls.WP_URL + 'strsdoor'}
                >
                  My Journeys
                </a>
              </NavItem>
            </DropdownItem>
            <DropdownItem>
              <NavItem>
                <a
                  className="nav-link rem-padding"
                  href={'/profiles/' + profileId}
                >
                 <TiStarOutline size={18} style= {{bottom: '0.125em', position: 'relative' }}/> My favourites
                </a>
              </NavItem>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  ContributorRoutes(): JSX.Element {
    return (
      <>
        <UncontrolledDropdown inNavbar nav>
          <DropdownToggle nav caret>
            Contribute
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link rem-padding"
                  activeClassName="active"
                  to="/contributor/items/add"
                >
                  <AiOutlineFileAdd
                    size={13}
                    style={{ bottom: '.125em', position: 'relative' }}
                  />{' '}
                  Add Items
                </NavLink>
              </NavItem>
            </DropdownItem>
            <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link rem-padding"
                  activeClassName="active"
                  to="/contributor/collections/add"
                >
                  <AiOutlineFileAdd
                    size={13}
                    style={{ bottom: '.125em', position: 'relative' }}
                  />{' '}
                  Add Collection
                </NavLink>
              </NavItem>
            </DropdownItem>
            {/* <DropdownItem>
              <NavItem>
                <NavLink
                  exact
                  tag={ReactLink}
                  className="nav-link rem-padding"
                  activeClassName="active"
                  to="/contributor/announcements/add"
                >
                  <AiOutlineFileAdd
                    size={13}
                    style={{ bottom: '.125em', position: 'relative' }}
                  />{' '}
                  Add Announcement
                </NavLink>
              </NavItem>
            </DropdownItem> */}
            <DropdownItem>
              <NavItem>
                <a
                  className="nav-link rem-padding"
                  href={config.urls.WP_URL + 'wp-admin/post-new.php'}
                >
                  <AiOutlineFileAdd
                    size={13}
                    style={{ bottom: '.125em', position: 'relative' }}
                  />{' '}
                  Add Journey
                </a>
              </NavItem>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      this.state.mailChimpModal &&
      !prevState.mailChimpModal &&
      this.state.errorMessage &&
      this.state.hideMailChimp
    ) {
      if (this._isMounted) {
        this.setState({
          hideMailChimp: false,
          warningMessage: undefined,
          errorMessage: undefined,
        });
      }
    }
  }

  mailChimpModalToggle = () => {
    if (this._isMounted) {
      if (!this.state.mailChimpModal) {
        // Get the users email
        const context: React.ContextType<typeof AuthContext> = this.context;
        this.setState({ email: context.email || '' });
      }

      this.setState({ mailChimpModal: !this.state.mailChimpModal });
    }
  };

  emailInvalid = (email: string) => !email.length || !validateEmail(email);

  handleSubmit = async (event) => {
    event.preventDefault();

    this.props.toggleOverlay(true);

    const scrollToAlert = function () {
      if (
        state.warningMessage !== undefined ||
        state.errorMessage !== undefined ||
        state.successMessage !== undefined
      ) {
        const alertRow = document.getElementById('mc_alerts');
        if (alertRow) {
          alertRow.scrollIntoView();
        }
      }
    };

    const state = {
      warningMessage: undefined,
      errorMessage: undefined,
      successMessage: undefined,
    };

    const formData = new FormData(event.target);

    // Only a bot would fill this out ...
    const honeyBotInput = formData.get(
      'b_8fe0e1048c67fb6cd5aa55bbf_f533c9b80d'
    ) as string;
    if (honeyBotInput && honeyBotInput.length) {
      return;
    } else {
      formData.delete('b_8fe0e1048c67fb6cd5aa55bbf_f533c9b80d');
    }

    const email = formData.get('EMAIL') as string;
    const emailInvalid = this.emailInvalid(email);

    if (emailInvalid && this._isMounted) {
      Object.assign(state, {
        errorMessage: 'Please enter a valid email address',
        emailInvalid,
      });
      this.props.toggleOverlay(false);
      if (this._isMounted) {
        this.setState(state, function () {
          scrollToAlert();
        });
      }
    } else {
      const postData = [`EMAIL=${email}`];

      const website = formData.get('MMERGE7');
      if (website) {
        postData.push(`MMERGE7=${website}`);
      }
      const fullname = formData.get('FULLNAME');
      if (fullname) {
        postData.push(`FULLNAME=${fullname}`);
      }
      const oceanUpdatesGroup = formData.get('group[4449][1]');
      if (oceanUpdatesGroup) {
        postData.push(`group[4449][1]=${oceanUpdatesGroup}`);
      }

      // send the request off.
      jsonp(
        `https://tba21.us18.list-manage.com/subscribe/post-json?u=8fe0e1048c67fb6cd5aa55bbf&id=f533c9b80d&${postData.join(
          '&'
        )}`,
        { param: 'c' },
        (err, data) => {
          if (data.msg.includes('already subscribed')) {
            Object.assign(state, {
              warningMessage: 'Looks like you\'re already subscribed!',
            });
            Object.assign(state, { hideMailChimp: true });
          } else if (err) {
            Object.assign(state, {
              errorMessage: 'We had some trouble signing you up.',
            });
          } else if (data.result !== 'success') {
            Object.assign(state, {
              errorMessage: 'We had some trouble signing you up.',
            });
          } else {
            Object.assign(state, { successMessage: data.msg });
          }

          Object.assign(state, { hideMailChimp: true });

          this.props.toggleOverlay(false);
          if (this._isMounted) {
            this.setState(state, function () {
              scrollToAlert();
            });
          }
        }
      );
    }
  };

  render() {
    const shouldDisplayNewsflash = this.state.newsflash?.enabled && this.state.newsflash?.text

    return (
      <AuthConsumer>
        {({ isAuthenticated, authorisation, logout }) => {
          const isAdmin =
            authorisation &&
            Object.keys(authorisation).length &&
            authorisation.hasOwnProperty('admin');
          return (
            <>
              { shouldDisplayNewsflash && (
                <NewsflashBanner
                  text={ this.state.newsflash?.text || '' }
                  countdownTo={ this.state.newsflash?.countdownDate ? new Date(this.state.newsflash?.countdownDate).getTime() : undefined }
                  link={ this.state.newsflash?.link || '#' }
                />
              ) }
              <div id="navigation">
                <Navbar className={ shouldDisplayNewsflash ? 'navbar-shifted' : '' } dark expand="md" fixed={this.props.fixed ? 'top' : ''}>
                  <div>
                    <NavbarBrand tag={ReactLink} to={'/'} className="mr-auto home">
                      <svg width="25px" height="23px" viewBox="0 0 295 233">
                        <path d="M187.7,180.2 L241.4,180.2 L241.4,232.6 L187.7,232.6 L101.6,186.7 L66.6,232.6 L0,232.6 L86.1,120 L187.7,180.2 Z M241.3,180.2 L241.3,127.7 L295,127.7 L295,180.2 L241.3,180.2 Z"/>
                        <path d="M283,10.1 L230.1,10.1 C230.8,18.4 229.8,37.6 219,44.7 C210.8,50.1 193.8,47.7 160.6,29.5 C156.1,27 151.6,24.7 147.2,22.6 C103.8,0.6 67.3,-7.5 41.8,9.5 C8.6,31.6 9.5,80.7 10.8,98.2 L63.7,98.2 C63,89.9 63.7,70.8 74.6,63.8 C82.8,58.4 100.8,60.8 133.9,79 C138.4,81.5 142.9,83.8 147.3,85.9 C190.7,107.9 227.1,114.2 252.6,97.2 C285.8,75.1 284.3,27.6 283,10.1"/>
                      </svg>
                    </NavbarBrand>
                    {/* <div style={{display: 'inline-block', position: 'relative', left: 23}}>
                      <a href="https://www.tba21.org/#item--academy--1819" target="_blank" rel="noreferrer" className="tba21logolink">
                        <TBA21Logo style={{height: 23, width: 'auto', verticalAlign: 'top'}}  />
                      </a>
                    </div> */}
                  </div>
                  <NavbarToggler
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.toggle();
                    }}
                  />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto float-right" navbar>
                      <NavItem>
                        <NavLink
                          exact
                          tag={ReactLink}
                          className="nav-link"
                          activeClassName="active"
                          to="/search"
                        >
                          Search
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          exact
                          tag={ReactLink}
                          className="nav-link"
                          active={
                            window.location.pathname.startsWith('/journeys') || window.location.pathname.startsWith('/story/')
                          }
                          activeClassName="active"
                          to="/journeys"
                        >
                          Journeys
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          exact
                          tag={ReactLink}
                          className="nav-link"
                          activeClassName="active"
                          to="/archive"
                        >
                          Archive
                        </NavLink>
                      </NavItem> */}
                      <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle nav caret>
                          Gardens
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <NavItem>
                              <a
                                className="nav-link"
                                href="https://garden.ocean-archive.org/seafloor-futures/"
                                target="_blank"
                              >
                                Mae Lubetkin: Seafloor Futures
                              </a>
                            </NavItem>
                          </DropdownItem>
                          <DropdownItem>
                            <NavItem>
                              <a
                                className="nav-link"
                                href="https://garden.ocean-archive.org/oaglossary/"
                                target="_blank"
                              >
                                Ocean Archive Glossary
                              </a>
                            </NavItem>
                          </DropdownItem>
                          <DropdownItem>
                            <NavItem>
                              <a
                                className="nav-link"
                                href="https://art-ocean-literacy.common.garden/"
                                target="_blank"
                              >
                                Art Ocean Literacy
                              </a>
                            </NavItem>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <NavItem>
                        <NavLink
                          exact
                          tag={ReactLink}
                          className="nav-link"
                          active={ window.location.pathname.startsWith('/portfolios') }
                          activeClassName="active"
                          to="/portfolios"
                        >
                          Portfolios
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          exact
                          tag={ReactLink}
                          className="nav-link"
                          activeClassName="active"
                          to="/map"
                        >
                          Map
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <a className="nav-link" href="https://community.ocean-archive.org/" target="_blank" rel="noopener noreferrer">ocean COMM/UNI/TY</a>
                      </NavItem>
                      {/* <NavItem>
                        <div
                          className="nav-link live"
                          id="liveButton"
                          onClick={() =>
                            this.props.liveStreamDispatch(true, true)
                          }
                        >
                          Live
                        </div>
                      </NavItem> */}
                      <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle nav caret>
                          About
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <NavItem>
                              <NavLink
                                exact
                                tag={ReactLink}
                                className="nav-link"
                                activeClassName="active"
                                to="/about"
                              >
                                Ocean Archive
                              </NavLink>
                            </NavItem>
                          </DropdownItem>
                          {/* <DropdownItem>
                            <NavItem>
                              <div
                                className="nav-link"
                                onClick={() =>
                                  this.setState({ isOnboardingShown: false })
                                }
                              >
                                Guide
                              </div>
                            </NavItem>
                          </DropdownItem> */}
                          <DropdownItem>
                            <NavItem>
                              <NavLink
                                exact
                                tag={ReactLink}
                                className="nav-link"
                                activeClassName="active"
                                to="/terms-and-conditions"
                              >
                                Terms
                              </NavLink>
                            </NavItem>
                          </DropdownItem>
                          <DropdownItem>
                            <NavItem>
                              <a className="nav-link"
                              onClick={() => {
                                this.mailChimpModalToggle();
                              }}
                            >
                              Subscribe
                            </a>
                            </NavItem>
                          </DropdownItem>
                          <DropdownItem>
                            <NavItem>
                              <NavLink
                                exact
                                tag={ReactLink}
                                className="nav-link"
                                activeClassName="active"
                                to="/privacy-policy"
                              >
                                Privacy
                              </NavLink>
                            </NavItem>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>

                      {isAuthenticated && isAdmin ? <this.AdminRoutes /> : <></>}

                      {isAuthenticated &&
                      (has(authorisation, 'contributor') ||
                        has(authorisation, 'admin')) ? (
                        <>
                          <this.AccountRoutes profileId={this.props.profileId}/>
                          <this.ContributorRoutes />
                        </>
                      ) : (
                        <></>
                      )}

                      {isAuthenticated ? (
                        <>
                          <NavItem>
                            <NavLink
                              exact
                              tag={ReactLink}
                              className="nav-link"
                              activeClassName="no"
                              to="/"
                              onClick={logout}
                            >
                              Logout
                            </NavLink>
                          </NavItem>
                        </>
                      ) : (
                        <NavItem>
                          <NavLink
                            exact
                            tag={ReactLink}
                            className="nav-link"
                            activeClassName="active"
                            to="/login"
                          >
                            Login / Sign-up
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>

                  {this.props.fixed && <div className="searchPadding" />}
                </Navbar>
                {/* <StoryOnboardingPopup
                  isOnboardingShown={this.state.isOnboardingShown}
                  setIsOnboardingShown={(isOnboardingShown) =>
                    this.setState({ isOnboardingShown: isOnboardingShown })
                  }
                /> */}
                <Modal
                  id="mailChimpModal"
                  className="blue"
                  isOpen={this.state.mailChimpModal}
                  backdrop
                  scrollable
                  centered
                  size="lg"
                  toggle={this.mailChimpModalToggle}
                >
                  <Row className="header align-content-center">
                    <Col xs="11" className="pl-0">
                      Subscribe to Our Mailing List
                    </Col>
                    <Col xs={1} className="px-0">
                      <div className="text-right closeIcon">
                        <FaTimes
                          className="closeButton"
                          onClick={this.mailChimpModalToggle}
                        />
                      </div>
                    </Col>
                  </Row>
                  <ModalBody>
                    <div id="mc_alerts">
                      <ErrorMessage message={this.state.errorMessage} />
                      <SuccessMessage message={this.state.successMessage} />
                      <WarningMessage message={this.state.warningMessage} />
                    </div>
                    <div id="mc_embed_signup">
                      <Form id="mc_form" onSubmit={this.handleSubmit}>
                        <div id="mc_embed_signup_scroll">
                          <div className="mc-field-group">
                            <Label htmlFor="mce-EMAIL">Email Address</Label>
                            <Input
                              type="email"
                              defaultValue={this.state.email}
                              name="EMAIL"
                              className="required email"
                              id="mce-EMAIL"
                              onChange={(e) => {
                                const emailInvalid = this.emailInvalid(
                                  e.target.value
                                );
                                if (this._isMounted) {
                                  this.setState({ emailInvalid });
                                }
                              }}
                              disabled={this.state.hideMailChimp}
                            />
                            <FormFeedback
                              style={
                                this.state.emailInvalid
                                  ? { display: 'block' }
                                  : { display: 'none' }
                              }
                            >
                              Email address is invalid.
                            </FormFeedback>
                          </div>
                          <div className="mc-field-group">
                            <Label htmlFor="mce-FULLNAME" className="pt-3">
                              Full Name{' '}
                            </Label>
                            <Input
                              type="text"
                              defaultValue=""
                              name="FULLNAME"
                              className=""
                              id="mce-FULLNAME"
                              disabled={this.state.hideMailChimp}
                            />
                          </div>
                          <div className="mc-field-group">
                            <Label htmlFor="mce-MMERGE7" className="pt-3">
                              Website{' '}
                            </Label>
                            <Input
                              type="url"
                              defaultValue=""
                              name="MMERGE7"
                              className=" url"
                              id="mce-MMERGE7"
                              disabled={this.state.hideMailChimp}
                            />
                          </div>
                          <div
                            id="mergeRow-gdpr"
                            className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                          >
                            <div className="content__gdpr pt-3">
                              <p>
                                Please select all the ways you would like to hear
                                from TBA21–Academy:
                              </p>
                              <fieldset
                                className="mc_fieldset gdprRequired mc-field-group"
                                name="interestgroup_field"
                              >
                                <CustomInput
                                  type="checkbox"
                                  label="Email"
                                  id="gdpr_55561"
                                  name="gdpr[55561]"
                                  className="av-checkbox"
                                  defaultValue="Y"
                                />
                              </fieldset>
                              <p className="pt-2">
                                You can unsubscribe at any time by clicking the
                                link in the footer of our emails. For information
                                about our privacy practices, please visit our
                                website.
                              </p>
                            </div>

                            {/* Add group*/}
                            <input
                              type="checkbox"
                              value="1"
                              name="group[4449][1]"
                              id="mce-group[4449]-4449-0"
                              defaultChecked
                              style={{ display: 'none' }}
                            />

                            <div className="content__gdprLegal pt-1">
                              <p>
                                We use Mailchimp as our marketing platform. By
                                clicking below to subscribe, you acknowledge that
                                your information will be transferred to Mailchimp
                                for processing.{' '}
                                <a
                                  href="https://mailchimp.com/legal/"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  Learn more about Mailchimp&apos;s privacy practices
                                  here.
                                </a>
                              </p>
                            </div>
                          </div>
                          <div id="mce-responses" className="clear">
                            <div
                              className="response"
                              id="mce-error-response"
                              style={{ display: 'none' }}
                            />
                            <div
                              className="response"
                              id="mce-success-response"
                              style={{ display: 'none' }}
                            />
                          </div>

                          <div
                            style={{ position: 'absolute', left: '-5000px' }}
                            aria-hidden="true"
                          >
                            <Input
                              type="text"
                              name="b_8fe0e1048c67fb6cd5aa55bbf_f533c9b80d"
                              tabIndex={-1}
                              defaultValue=""
                            />
                          </div>
                          <div className="clear">
                            <Button
                              type="submit"
                              name="subscribe"
                              id="mc-embedded-subscribe"
                              className="button"
                              disabled={this.state.hideMailChimp}
                            >
                              Subscribe
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </>
          );
        }}
      </AuthConsumer>
    );
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: { profile: any, global: any }) => ({
  profileId: state.profile?.details?.id
});


export default connect(mapStateToProps, {
  modalToggle,
  toggleOverlay,
  fetchCategories,
  fetchTags,
  liveStreamDispatch,
  getCurrentUserProfile: getCurrentUserProfileById
})(withRouter(HeaderClass));
