import React, { RefObject } from 'react';
import { StoryListState } from '../../reducers/story/storyList';
import { connect } from 'react-redux';
import {
  FETCH_STORIES_INITIAL_SUCCESS,
  FETCH_STORIES_INCREMENTAL_SUCCESS,
} from '../../actions/story/storyList';
import { fetchCategories } from '../../actions/story/storyList';
import { WP_REST_API_Terms, WP_REST_API_Tags } from 'wp-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

type StorySearchesProps = {
  title: string;
  setTitle: Function;
  selectedCategories: WP_REST_API_Terms;
  setSelectedCategoryIds: Function;
  selectedTags: WP_REST_API_Tags;
  setSelectedTagIds: Function;
  orderBy: 'author' | 'title' | 'date';
  setOrderBy: Function;
  orderTitle: 'asc' | 'desc';
  orderAuthor: 'asc' | 'desc';
  setOrderAuthor: Function;
  setOrderTitle: Function;
  totalStories: number;
  totalStoriesInDatabase: number;
  status: StoryListState['status'];
  parentToChildCategory: StoryListState['parentToChildCategory'];
  tags: WP_REST_API_Terms;
  authors: StoryListState['authors'];
  wrapperRef: RefObject<HTMLDivElement>;
  isSticky: boolean;
  showSearchMobile: boolean;
  toggleShowSearchMobile: Function;
};

const StorySearches: React.FC<StorySearchesProps> = ({
  wrapperRef,
  title,
  setTitle,
  selectedCategories,
  setSelectedCategoryIds,
  orderBy,
  setOrderBy,
  orderTitle,
  orderAuthor,
  setOrderAuthor,
  setOrderTitle,
  totalStories,
  totalStoriesInDatabase,
  status,
  parentToChildCategory,
  tags,
  setSelectedTagIds,
  selectedTags,
  isSticky,
  showSearchMobile,
  toggleShowSearchMobile,
}) => {
  return (
    <div
      className={`stories__searches ${
        isSticky ? 'stories__searches--sticky' : ''
      } ${showSearchMobile ? '' : 'mobile-hidden'}`}
      ref={wrapperRef}
    >
      <p
        style={{
          opacity:
            status === FETCH_STORIES_INITIAL_SUCCESS ||
            status === FETCH_STORIES_INCREMENTAL_SUCCESS
              ? 1
              : 0.4,
        }}
      >{`Displaying ${totalStories} out of ${totalStoriesInDatabase} Journeys`}</p>

      <input
        type="text"
        placeholder="Search Journeys"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className="autocomplete">
        <UncontrolledDropdown>
          <DropdownToggle nav caret className="story-dropdown">
            {'Search by keyword tags'}
          </DropdownToggle>
          <DropdownMenu style={{ maxHeight: '28rem', overflowY: 'scroll' }}>
            {Array.from(tags).map((tag) => (
              (tag.count > 0) ? (
              <DropdownItem
                key={tag.id}
                onClick={() => {
                  setSelectedTagIds(tag.id);
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: tag.name }} />
                <div style={{ opacity: 0.6 }}>{tag.count} journeys</div>
              </DropdownItem>) : ((<React.Fragment key={tag.id} />))
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      {parentToChildCategory
        .filter((parentCategory) => parentCategory.name !== 'Uncategorized' && parentCategory.name !== 'preview') // don't show Uncategorized or preview as possible search category
        .map((parentCategory) => (
          <div className="autocomplete" key={parentCategory.id}>
            <UncontrolledDropdown>
              <DropdownToggle nav caret  className="story-dropdown">
                {`Search by ${parentCategory.name === 'authors' ? 'author' : parentCategory.name}`}
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: '28rem', overflowY: 'scroll' }}>
                {parentCategory.categories.map((category) => (
                  (category.count > 0) ? (
                  <DropdownItem
                    key={category.id}
                    onClick={() => {
                      setSelectedCategoryIds(category.id);
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: category.name }} />
                    <div style={{ opacity: 0.6 }}>{category.count} journeys</div>
                  </DropdownItem>) : (<React.Fragment key={category.id} />)
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ))}

      <div className="categories-wrapper">
        {selectedCategories.map((category) => {
          return (
            <span className="category-tag" key={category.id}>
              <span>{category.name}</span>
              <span
                onClick={() => {
                  setSelectedCategoryIds(category.id);
                }}
              >
                x
              </span>
            </span>
          );
        })}
        {selectedTags.map((tag) => {
          return (
            <span className="category-tag" key={tag.id}>
              <span>{tag.name}</span>
              <span
                onClick={() => {
                  setSelectedTagIds(tag.id);
                }}
              >
                x
              </span>
            </span>
          );
        })}
      </div>
      <br />
      <p className="stories__searches-orderby-title">View Journeys by:</p>
      <div className="stories__searches-orderby">
        {ORDER_BY.map(({ label, value }) => (
          <div className="radio-wrapper" key={value}>
            <input
              type="radio"
              id={value}
              name="orderby"
              value={value}
              checked={value === orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
            />
            <label htmlFor={value}>{label}</label>
            {value === 'author' && (
              <UncontrolledDropdown
                direction={orderAuthor === 'asc' ? 'down' : 'up'}
              >
                <DropdownToggle nav caret>
                  {orderAuthor === 'asc' ? 'A-Z' : 'Z-A'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setOrderAuthor('asc')}>
                    Ascending (A-Z)
                  </DropdownItem>
                  <DropdownItem onClick={() => setOrderAuthor('desc')}>
                    Descending (Z-A)
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {value === 'title' && (
              <UncontrolledDropdown
                direction={orderTitle === 'asc' ? 'down' : 'up'}
              >
                <DropdownToggle nav caret>
                  {orderTitle === 'asc' ? 'A-Z' : 'Z-A'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setOrderTitle('asc')}>
                    Ascending (A-Z)
                  </DropdownItem>
                  <DropdownItem onClick={() => setOrderTitle('desc')}>
                    Descending (Z-A)
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
        ))}
      </div>
      <button
        className="stories__searches-apply-button-mobile"
        onClick={() => toggleShowSearchMobile(false)}
      >
        Apply
      </button>
      <img
        src="/svg/circular-variant-1.svg"
        alt=""
        className="stories__searches__illustration"
      />
    </div>
  );
};

const ORDER_BY = [
  { value: 'date', label: 'Newest' },
  { value: 'author', label: 'Author' },
  { value: 'title', label: 'Title' },
];

const mapStateToProps = (state: { storyList: StoryListState }) => ({
  totalStories: state.storyList.stories.length,
  totalStoriesInDatabase: state.storyList.totalStoriesInDatabase,
  status: state.storyList.status,
  parentToChildCategory: state.storyList.parentToChildCategory,
  tags: state.storyList.tags,
  authors: state.storyList.authors,
});

export default connect(mapStateToProps, {
  fetchCategories,
})(StorySearches);
